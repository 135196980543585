import React, { useEffect, useState, useContext } from "react"
import { useTranslation } from "next-i18next"
import { FirebaseContext } from "./Firebase"
import LoadingButton from "./LoadingButton"
import Link from "next/link"
import { aiGenerations } from "./FirebaseApp"

const ImageListItem = ({
  id,
  image,
  status,
  action,
  roomType,
  style,
  texture,
  customInput,
  created,
  isGrid,
  type,
  linkName = "ai-generator",
  onClick,
  seo_title,
  seo_url,
}) => {
  const urlName = type === "Interior Design AI" ? "interior-design-ai" : linkName
  const link = id ? `/${urlName}/b/${seo_url ? seo_url : id}` : "#"
  const callToAction = typeof onClick === "function" ? onClick(image) : () => {}
  return (
    <div className="flex flex-col">
      <Link href={link} passHref>
        <a
          className={`border border-gray-100 bg-gradient-to-b from-white to-gray-50 block relative overflow-hidden cursor-pointer rounded-xl ${
            !id ? "animate-pulse" : ""
          }`}
          onClick={e => callToAction(e)}
        >
          <div className="relative w-full h-0 pb-[100%]"> {/* 1:1 aspect ratio container */}
            <img
              src={image}
              className="absolute top-0 left-0 w-full h-full object-cover"
              alt={seo_title ? seo_title : id}
              loading="lazy"
            />
            {isGrid && (
              <div className="absolute top-0 right-0 uppercase p-2 bg-gray-200 text-xs text-gray-700">
                Grid
              </div>
            )}
          </div>
        </a>
      </Link>
    </div>
  )
}
export const UserImages = ({ uid, hideGrids = true, images: propImages, type, roomType, style, linkName, onClick, pageSize = 12 }) => {
  const fb = useContext(FirebaseContext)
  const { t } = useTranslation()
  const { app } = fb
  const [images, setImages] = useState(propImages || [])
  const [lastDoc, setLastDoc] = useState(propImages ? propImages[propImages.length - 1]?.created : null)
  const [hasMore, setHasMore] = useState(true)
  const [currentType, setCurrentType] = useState(type)
  const [currentRoomType, setCurrentRoomType] = useState(roomType)
  const [currentStyle, setCurrentStyle] = useState(style)
  const [hideGridsState, setHideGridsState] = useState(hideGrids)

  const fetchImages = async (params) => {
    const { type, lastCreatedValue, hideGrids, roomType, style } = params || {}
    const aiRef = await aiGenerations()
    
    let aiImageRef = aiRef
      .where("status", "==", "SUCCESS")
      .orderBy("created", "desc")
      .limit(pageSize)

    if (hideGrids) {
      aiImageRef = aiImageRef.where("isGrid", "==", false)
    }

    if (type) {
      aiImageRef = aiImageRef.where("type", "==", type)
    }

    if (roomType) {
      aiImageRef = aiImageRef.where("roomType", "==", roomType)
    }

    if (style) {
      aiImageRef = aiImageRef.where("style", "==", style)
    }

    if (uid) {
      aiImageRef = aiImageRef.where("uid", "==", uid)
    }

    if (lastCreatedValue) {
      let lastCreatedTimestamp = new app.firestore.Timestamp(
        lastCreatedValue.seconds,
        lastCreatedValue.nanoseconds
      )
      aiImageRef = aiImageRef.startAfter(lastCreatedTimestamp)
    }

    const aiImagesCollection = await aiImageRef.get()
    const newImages = aiImagesCollection.docs.map(doc => ({
      ...doc.data(),
      id: doc.id,
    }))
    if (newImages.length > 0) {
      setLastDoc(newImages[newImages.length - 1].created)
      setImages(oldImages => [...oldImages, ...newImages])
    } else {
      setHasMore(false)
    }
  }

  useEffect(() => {
    if(!propImages) fetchImages({hideGrids})
  }, [propImages])

  useEffect(() => {
    if (
      currentType !== type ||
      currentRoomType !== roomType ||
      currentStyle !== style
    ) {
      setImages([])
      setHasMore(true)
      setCurrentType(type)
      setCurrentRoomType(roomType)
      setCurrentStyle(style)
      fetchImages({ type, roomType, style, hideGrids })
    }
  }, [type, roomType, style])

  const onHideGrids = async () => {
    setImages([])
    setHasMore(true)
    setHideGridsState(true)
    fetchImages({hideGrids: true})
  }

  return (
    <div className="my-10 2xl:mx-10">
      {!hideGrids && <div className="text-center">
        <LoadingButton
            onClick={onHideGrids}
            className="mx-auto"
          >
            Hide grids
      </LoadingButton>
      </div>}
      <section className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-8 sm:gap-12 2xl:gap-14 text-gray-600 p-6 sm:p-8 ">
        {images.map((image, i) => (
          <ImageListItem key={i} {...image} linkName={linkName} onClick={onClick} />
        ))}
      </section>
      {hasMore && (
        <div className="text-center">
          <LoadingButton
            onClick={async () => await fetchImages({lastCreatedValue: lastDoc, type, roomType, style, hideGrids: hideGridsState})}
            highlight
          >
            {t("load-more")}
          </LoadingButton>
        </div>
      )}
    </div>
  )
}
